import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Service from "../components/Services"
import ServiceEn from "../components/ServicesEn"
import SEO from "../components/SEO"
import { useLanguage } from "../contexts/LanguageContext"
const Services = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook

  return (
    <Layout>
      <SEO title="Usluge" />
      <div className="img-wrapper img-wrapper-about ">
        <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter ">
            Assortment of Services
          </h2>
        ) : (
          <h2 className="about-header visibility-filter ">Asortiman Usluga</h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">Services</h2>
        ) : (
          <h2 className="about-header mobile-visible">Usluge</h2>
        )}
      </div>
      {selectedLanguage === "EN" ? <ServiceEn /> : <Service />}
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "pexels-laura-tancredi-7078708 (1).jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Services
